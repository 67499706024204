import React from "react"
import { graphql } from "gatsby";
import { Container } from "@material-ui/core";

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SectionTitle, BannerImage, LinkA, CourseList, Text } from "../styles/pages/vestibular";

import Banner from "../images/students-group.jpg";

export default function VestibularPage(props) {

  return (
    <Layout>
      <SEO title="Página de vestibular" />
      {/* Banner */}
      <Container style={{ marginTop: 30 }}>
        <SectionTitle style={{ borderBottom: '2px solid #ef4e45' }}>Vestibular Online</SectionTitle>
        <BannerImage src={Banner} alt="Banner" />
      </Container>

      {/* Subscribe Section */}
      <Container>
        <SectionTitle style={{ paddingLeft: 0, marginBottom: 0 }}>Inscrições Abertas</SectionTitle>
        <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual" style={{ marginBottom: 10 }}>Quero Graduação Presencial</LinkA>
        <CourseList style={{ marginBottom: 30 }}>
          <li>Bacharelado em Enfermagem - <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Mensalidade R$ 590,00</LinkA></li>
          <li>Bacharelado em Administração - <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Mensalidade R$ 325,50</LinkA></li>
          <li>Bacharelado em Ciências Contábeis - <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Mensalidade R$ 325,50</LinkA></li>
          <li>Bacharelado em Serviço Social - <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Mensalidade R$ 340,00</LinkA></li>
          <li>Bacharelado em Pedagogia - <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Mensalidade R$ 278,60</LinkA></li>
          <li>Bacharelado em Letras - <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Mensalidade R$ 278,60</LinkA></li>
          <li>Bacharelado em Gestão de Recursos Humanos - <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Mensalidade R$ 278,60</LinkA></li>
        </CourseList>
        <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual" style={{ marginBottom: 10 }}>Quero Graduação EAD</LinkA>
        <CourseList>
          <li>Bacharelado em Administração - <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Mensalidade R$ 174,00</LinkA></li>
          <li>Licenciatura em Pedagogia - <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Mensalidade R$ 152,75</LinkA></li>
          <li>Licenciatura em Letras - <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Mensalidade R$ 152,75</LinkA></li>
          <li>Tecnólogo em Gestão de Recursos Humanos - <LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Mensalidade R$ 164,00</LinkA></li>
        </CourseList>
      </Container>

      {/* Online Vestibule */}
      <Container>
        <SectionTitle>Prova Online</SectionTitle>
        <Text><span style={{ fontWeight: 'bold' }}>O vestibular será on-line,</span> preencha o formulário e receba um e-mail com as orientações para a realização da prova</Text>
        <CourseList>
          <li><LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Vestibular para o Presencial</LinkA></li>
          <li><LinkA target="_blank" href="http://faculdadejardins-com-br.rds.land/vestibular-virtual">Vestibular para o EAD</LinkA></li>
        </CourseList>
        <Text>A divulgação do resultado será em até 72h pelo e-mail, WhatsApp e/ou ligação. Dúvidas fale conosco <a href="mailto:comercial@facjardins.com.br">comercial@facjardins.com.br</a> ou WhatsApp 55 79 99685-5993</Text>
        <Text>Recomendamos realizar o pagamento da matrícula do curso escolhido para já garantir o valor promocional, ressaltamos que em caso de não aprovação ou desistência iremos restituir 100% do valor pago.</Text>
      </Container>

      {/* ENEM */}
      <Container>
        <SectionTitle>Inscrições via ENEM</SectionTitle>
        <Text>
          <span style={{ fontWeight: 'bold' }}>Taxa de inscrição:</span> Isenta, mas deverão incluir o número de inscrição
        no ENEM, para validar a sua nota a redação não pode ter sido zerada.
        Se por meio deste número de inscrição não for possível acessar o seu boletim,
        será solicitado ao mesmo que apresente uma cópia do <span style={{ fontWeight: 'bold' }}>Boletim de Desempenho
        Individual de Notas</span> emitido pelo Ministério da Educação, com o resultado
        do ENEM à Secretaria da Faculdade Jardins.
      </Text>
      </Container>

      {/* DOCS*/}
      <Container>
        <SectionTitle>Documentos para Matrícula</SectionTitle>
        <CourseList style={{ listStyleType: 'lower-alpha' }}>
          <li>O candidato convocado, no ato da matrícula, deverá enviar para o e-mail secretaria@facjardins.com.br com os seguintes documentos:</li>
          <CourseList style={{ marginLeft: 20 }}>
            <li>Histórico Escolar do Ensino Médio ou equivalente;</li>
            <li>Certificado de Conclusão do Ensino Médio ou equivalente (caso o aluno ainda não tenha o certificado, deverá assinar uma declaração comprometendo-se a entregar o documento em até 60 dias;</li>
            <li>Certidão de nascimento ou de casamento;</li>
            <li>Documento de identidade (RG);</li>
            <li>Cadastro de Pessoa Física (CPF), não possuindo ou sendo menor de 18 anos, entregar cópia do CPF do pai ou responsável legal;</li>
            <li>Comprovante de residência recente (conta de luz ou de telefone, contendo CEP);</li>
            <li>Em caso de desconto concedido a partir de parcerias, apresentar o documento comprobatório do vínculo;</li>
          </CourseList>
          <li>Assinar em até 15 dias o Contrato de Prestação de Serviços Educacionais (emitido pela Faculdade Jardins), que será enviado por e-mail para assinatura digital pelo candidato ou por seu responsável legal.</li>
          <li>Declaração de Autenticidade, na qual o aluno declara: haver concluído o Ensino Médio
          da Educação Básica e estar, devidamente, habilitado ao ingresso no Ensino Superior;
          que as cópias simples dos documentos listados representam total fidedignidade
          aos seus respectivos originais: RG, CPF, Comprovante de Residência, Histórico Escolar
          e Certificado de Conclusão de Ensino Médio; a leitura integral e aceitação de todos os Termos do Contrato de
          Prestação de Serviços Educacionais e a ciência de que, se inverídicas suas declarações, responderá nos termos do
        ordenamento jurídico vigente, em especial, à luz do Código Penal Brasileiro.</li>
          <li>O candidato que entregar documento de conclusão de curso do Ensino Médio ou equivalente, realizado no exterior,
          deverá apresentar documento de equivalência formal do referido curso, expedido
          pela Secretaria Estadual da Educação, com data de emissão anterior à da realização
        da matrícula.</li>
          <li>Para os candidatos que ainda estão cursando o Ensino Médio, o mesmo deverá
        trazer uma Declaração da Escola com data de conclusão do curso.</li>
        </CourseList>
      </Container>

      {/* DOUBTS */}
      <Container>
        <SectionTitle>Dúvidas sobre Vestibular?</SectionTitle>
        <p>WhatsApp Faculdade Jardins <br></br>
          <a href="https://api.whatsapp.com/send?phone=5579996855993" style={{ fontWeight: 'bold' }}>Comercial: (79) 99685-5993</a>
        </p>
        <p>
          Ou nos envie um e-mail <a href="mailto:comercial@facjardins.com.br">comercial@facjardins.com.br</a><br></br>
        Atendimento realizado de segunda-feira a sexta-feira, das 8h às 20h
      </p>
      </Container>

      {/* DOWNLOADS */}
      <Container>
        <SectionTitle>Downloads</SectionTitle>
        <LinkA href={props.data.file.publicURL} target="_blank">Edital Vestibular</LinkA>
      </Container>
    </Layout>
  )
}

export const pagequery = graphql`
  query {
    file(name: {eq: "Edital Processo Seletivo - 2024.1"}) {
      publicURL
    }
  }
`